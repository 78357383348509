import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
  CFixedImg,
  CBtnList,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'SDGs',
            sub: 'SDGs',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/sdgs/kv.png',
              },
              imgSp: {
                src: '/assets/images/sdgs/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'SDGs',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <p className="c_sectLead u_mb30">
            横浜ロイヤルパークホテルは、SDGs（持続可能な開発目標）に賛同し、
            <br className="u_pc" />
            サステナブル（持続可能）な社会の実現につとめることを目指し、様々な取り組みを積極的に行っております。
          </p>
          <CFixedImg
            width={840}
            img={{
              src: '/assets/images/sdgs/img_sdgs.png',
              alt: '',
            }}
          />
        </LWrap>
      </section>
      <section className="u_bgWhite">
        <LWrap>
          <h2 className="c_headingLv3 u_tac">Y-SDGs スプリーム取得</h2>
          <CFixedImg
            exClass="u_mb25"
            width={840}
            img={{
              src: '/assets/images/sdgs/img_sdgs02.png',
              alt: '',
            }}
          />
          <p className="u_mb20 u_fs14">
            2022年7月、神奈川県横浜市が定める「横浜市SDGs認証制度“Y-SDGs”」にて、最上位認証事業者である「Supreme（スプリーム）」の認証を取得しました。
            <br />
            認証区分の中でも、「環境」「社会」「ガバナンス」「地域」の4つの分野30項目において、高いレベルで取り組みを進める事業者に与えられる最上位の認証で、横浜市内のホテルでは初めての功績となります。
            <br />
            特に、SDGsの「目標8：働きがいも経済成長も」「目標12：つくる責任つかう責任」「目標16：平和と公正をすべての人に」の3つの目標に対し、高い評価を得ました。
          </p>
          <p>
            ＜認証取得歴＞
            <br />
            2021年6月
            <br />
            <Link
              to="https://www.city.yokohama.lg.jp/kurashi/machizukuri-kankyo/ondanka/futurecity/y-sdgs/ysdgs.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              「横浜市SDGs認証制度“Y-SDGs”」
            </Link>
            　上位認証事業者「Superior（スーペリア）」の認証を取得
            <br />
            <span className="u_fs12">
              「目標6：安全な水とトイレを世界中に」と「目標15：陸の豊かさも守ろう」に対し高い評価を得ました。
            </span>
            <br />
            2022年5月
            <br />
            <Link
              to="https://www.pref.kanagawa.jp/docs/bs5/sdgs/partner.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              「かながわSDGsパートナー」
            </Link>
            に認定
          </p>
        </LWrap>
      </section>

      <section className="l_sect u_bgWhite u_pt60">
        <LWrap>
          <div className="u_mb40">
            <h2 className="c_headingLv3 u_tac">SDGsの取り組み</h2>
            <CFixedImg
              exClass="u_mb25"
              width={840}
              img={{
                src: '/assets/images/sdgs/img_sdgs03.png',
                alt: '',
              }}
            />
            <p>
              ■環境に配慮した客室
              <br />
              一部の客室では、プラスチックの使用量を40％削減したエコアメニティや100%リサイクルの再生ペットボトルを使用したミネラルウォーターの導入、ロクシタン社が実施するバスアメニティボトルの回収に協力しております。
              <br />
              57F〜59F
              レギュラーフロアでは、1993年開業当初から使用していた上質な家具を廃棄することなく、修理をして再活用。
              不要となった羽毛布団を「グリーンダウンプロジェクト」へ提供するなど、廃棄を減らす取り組みを行っております。
            </p>
          </div>

          <div className="u_mb40">
            <CFixedImg
              exClass="u_mb25"
              width={840}
              img={{
                src: '/assets/images/sdgs/img_sdgs08.png',
                alt: '',
              }}
            />
            <p className="u_mb15">
              ■「耳まで白い食パン」の使用
              <br />
              「耳」を切り落とす必要がなく、廃棄が出ない「耳まで白い食パン」を使用したサンドウィッチを提供し、食品ロスの削減に取り組んでおります。
            </p>
          </div>

          <div className="u_mb40">
            <CFixedImg
              exClass="u_mb25"
              width={840}
              img={{
                src: '/assets/images/sdgs/img_sdgs04.png',
                alt: '',
              }}
            />
            <p className="u_mb15">
              ■ロスフラワーの削減
              <br />
              デリカ＆ラウンジ「コフレ」では、ホテルで余ってしまい、そのままだと廃棄されてしまうお花を小さなブーケにして、
              月・水曜日限定で販売し、ロスフラワーの削減に努めております。
            </p>
            <p>
              ■食品ロスの削減
              <br />
              クラブラウンジでは、消費期限が近いなどの理由で本来は破棄されるデリカ＆ラウンジ「コフレ」のブレッドを、無償提供しております。
            </p>
            <ul className="c_noteList u_mb15">
              <li>場合により提供がない日もございます。</li>
            </ul>
            <p>
              ■3010運動推奨
              <br />
              環境省が推奨する3010運動に賛同し、ご宴会の幹事様にご案内しております。
            </p>
            <ul className="c_noteList u_mb15">
              <li>
                3010運動とは…宴会の時の食品ロスを減らすためのキャンペーンで、乾杯からの30分間とお開き前の10分間は自分の席で料理を楽しみ、食べ残しを減らそうと呼び掛ける運動です。
              </li>
            </ul>
            <p className="u_mb15">
              ■慈善団体への寄付
              <br />
              横浜市西区の慈善団体
              <Link
                to="https://oimatsukids.family.blog/harenohi-kenohi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                「こども食堂 ハレの日ケの日」
              </Link>
              に、ロスフラワーや賞味期限の近い食品などを寄付しております。
            </p>
          </div>

          <div className="u_mb40">
            <CFixedImg
              exClass="u_mb25"
              width={840}
              img={{
                src: '/assets/images/sdgs/img_sdgs05.png',
                alt: '',
              }}
            />
            <p className="u_mb15">
              ■フェアトレード商品の導入
              <br />
              開発途上国の原料や製品を適正な価格で継続的に購入する認証ワイン（フェアフォーライフ認証、
              国際フェアトレード認証）、
              国際フェアトレード認証コーヒーを導入しております。
            </p>
            <p className="u_mb15">
              ■生分解性ストローの導入
              <br />
              ホテル内全てのレストラン&バーにて、環境に優しい「生分解性ストロー」を導入しております。
            </p>
          </div>

          <div className="u_mb40">
            <CFixedImg
              exClass="u_mb25"
              width={840}
              img={{
                src: '/assets/images/sdgs/img_sdgs06.png',
                alt: '',
              }}
            />
            <p className="u_mb15">
              ■地産地消の取り組みの推進
              <br />
              積極的に神奈川県産の食材を取り入れ、レストランや宴会で提供しております。
            </p>
            <p className="u_mb15">
              ■ベジブロスの有効活用
              <br />
              野菜の切れ端などを捨てることなく、だしとして有効活用。ベジブロスを使用したメニューも提供しております。
            </p>
          </div>

          <CFixedImg
            exClass="u_mb25"
            width={840}
            img={{
              src: '/assets/images/sdgs/img_sdgs07.png',
              alt: '',
            }}
          />
          <p className="u_mb15">
            ■排水の再利用
            <br />
            横浜ランドマークタワー内のホテルや飲食店舗の厨房などから出た排水を中水道設備で浄化し、トイレの洗浄水として再利用しております。
            水資源を有効活用するとともに、排水量を削減し、下水道の負担を軽減しております。
          </p>
          <p>
            ■再生可能エネルギー由来電力の導入
            <br />
            食品廃棄物から発生させたバイオガスなどの再生可能エネルギー由来の電力を導入することによって、横浜ランドマークタワー全体でCO2を年間約18万トン、
            ホテルでは約9千トン削減しております。
          </p>
          <ul className="c_noteList u_mb60">
            <li>2019年度の削減数です。</li>
            <li>この取り組みは三菱地所グループの取り組みのひとつです。</li>
          </ul>
          <CBtnList
            data={[
              {
                size: 'large',
                label: 'ロイヤルパークホテルズの取り組み',
                link: {
                  href: `https://www.royalparkhotels.co.jp/about/sdgs/`,
                  blank: true,
                },
                icon: 'blank',
              },
              {
                size: 'large',
                label: '三菱地所グループの取り組み',
                link: {
                  href: `https://mec.disclosure.site/j/sustainability/`,
                  blank: true,
                },
                icon: 'blank',
              },
            ]}
          />
          <ul className="c_noteList u_mtLarge">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
